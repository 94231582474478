import React from "react";

import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ReferenceField,
  EditButton,
  Edit,
  Create,
  TabbedForm,
  FormTab,
  ReferenceInput,
  AutocompleteInput,
  Filter,
} from "react-admin";

export const UserPanelFilter: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const UserPanelList: React.FC = (props) => (
  <List {...props} filters={<UserPanelFilter />} exporter={false}>
    <Datagrid>
      <ReferenceField label="User" source="userId" reference="User">
        <TextField source="email" />
      </ReferenceField>

      <ReferenceField label="Panel" source="panelId" reference="Panel">
        <TextField source="name" />
      </ReferenceField>

      <EditButton basePath="/UserPanel" />
    </Datagrid>
  </List>
);

export const UserPanelEdit: React.FC = (props) => (
  <Edit title="Update UserPanel" {...props}>
    <TabbedForm>
      <FormTab label="Basic Details">
        <TextInput source="id" disabled />

        <ReferenceInput label="User Email" source="userId" reference="User">
          <AutocompleteInput optionText="email" />
        </ReferenceInput>

        <ReferenceInput label="Panel Name" source="panelId" reference="Panel">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const UserPanelCreate: React.FC = (props) => (
  <Create title="Create a UserPanel" {...props}>
    <TabbedForm>
      <FormTab label="Basic Details">
        <ReferenceInput label="User" source="userId" reference="User">
          <AutocompleteInput optionText="email" />
        </ReferenceInput>

        <ReferenceInput label="Panel" source="panelId" reference="Panel">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
